import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Person from "./"

export default function PersonList({ shortnames }) {
  const people = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { posttype: { eq: "person" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              shortname
              role
              filepath
            }
            html
          }
        }
      }
    }
  `)

  let filteredPeople = people.allMarkdownRemark.edges

  if (shortnames.length) {
    filteredPeople = filteredPeople.filter(f =>
      shortnames.includes(f.node.frontmatter.shortname)
    )
  }

  return (
    <div className="person-list">
      {filteredPeople.map(p => (
        <Person
          key={p.node.id}
          image={p.node.frontmatter.filepath}
          name={p.node.frontmatter.name}
          role={p.node.frontmatter.role}
          bio={p.node.html}
        />
      ))}
    </div>
  )
}

PersonList.defaultProps = {
  shortnames: [],
}
