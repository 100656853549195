import React from "react"

import { LandingLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PersonList from "../components/person/PersonList"

import {
  ScheduleVisitButton,
} from "../components/buttons"


const LandingTheVillages = () => {
  return (
    <LandingLayout>
      <SEO
        title="The Long Term Care Insurance Consultation with Corey Rieck"
        ogDescription="We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
          <h1>
            Thank You For Visiting From The Villages in Florida.
          </h1>
          <p>
            Corey Rieck is the president and founder of The Long Term Care Planning Group. He helps individuals better manage long-term care expenses to avoid sacrificing their financial plans and family relationships.
          </p>

          </div>
        }
        textRight={
          <div>
            <img
              src="/images/landing/the-villages-logo.jpg"
              alt="The Villages"
            />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
          <p>
            Corey became focused on long-term care planning after managing his parents’ finances as they aged and needed long term care. He realized there had to be a better way for people to receive the best possible long term care without forfeiting assets and depleting their bank accounts.
          </p>
          <p>
            Corey knows how to help protect your financial assets and wealth management with a funding strategy for long term care. He can answer your questions and explain why you should not rely upon government programs or only your family members for long term care.  
          </p>
          <p>
            We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs and answer all your questions on long term care. This consult fee, normally valued at $99.95, is waived when you mention "The Villages."
          </p>

          <h2>Request a meeting or phone call</h2>
          
          <p>
          Click the button below to schedule your free, no-obligation LTC Consult with Corey Rieck of The Long Term Care Planning Group (a $99.95 value). Or, you can call <a href="tel:6788145088">(678) 814-5088</a> to schedule a meeting over the phone.
          </p>

          <p>
           <ScheduleVisitButton />
          </p>

          <h2>Or ... sign up for our free webinar</h2>
          <p>
            Sometimes the best way to learn more about a complex topic is to attend a short webinar to start with the basics. Even if you already have done long term care planning, you may still have unanswered questions about your coverage or plan. This webinar is about 20 minutes. Corey quickly introduces himself and then covers important details you need to know about long term care planning. You'll also receive a summary PDF overview for your review.
          </p>
          
          <div class="widget_wrapper"><div class="ewp_form_container ewp_widget_container" webinar_id="QdvUctflYjEnWYhjc65cdg==" ></div> <script type="text/javascript" src="https://ewpcdn-ecs.easywebinar.com/widget/js/ewp_widget.js?v=2.1.290" ></script></div>
          
        </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </LandingLayout>
  )
}

export default LandingTheVillages
